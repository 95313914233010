<template>
  <picture :key="nSources[typeof nSources[1] !== 'undefined' ? 1 : 0].src" :class="classList">
    <template v-for="sources in nSources">
      <template v-for="(srcset, index) in sources.srcset">
        <source
          v-if="srcset"
          :key="`source-${sources.type}-${index}`"
          :type="sources.type"
          :srcset="`${sources.srcset[index]}${version} 1x, ${sources.srcset2x[index]}${version} 2x`"
          :media="sources.media[index]"
          :width="sources.widths[index]"
          :height="height ? height : undefined"
        >
      </template>
    </template>
    <img
      v-bind="{...nImgAttrs, ...imgAttrs}"
      :src="`${nSources[typeof nSources[1] !== 'undefined' ? 1 : 0].src}${version}`"
      :loading="loading"
      v-on="$listeners"
      @load="imgLoaded = true"
    >
  </picture>
</template>

<script>
import { imageMixin } from '@nuxt/image/dist/runtime/components/image.mixin'
import { getFileExtension } from '~image'

const defineComponent = opts => opts
export default defineComponent({
  name: 'JbPicture',
  mixins: [imageMixin],
  props: {
    legacyFormat: { type: String, default: null },
    imgAttrs: { type: Object, default: null },
    loading: { type: String, default: undefined }
  },
  data: () => ({
    imgLoaded: false
  }),
  head () {
    if (process.env.NODE_ENV === 'production' && this.preload === true) {
      const vm = this
      const links = []

      // eslint-disable-next-line array-callback-return,@typescript-eslint/no-unused-vars
      this.nSources[0].srcset.map((srcset, index) => {
        links.push({
          rel: 'preload',
          as: 'image',
          imagesrcset: `${vm.nSources[0].srcset[index]}${vm.version} 1x, ${vm.nSources[0].srcset2x[index]}${vm.version} 2x`,
          media: vm.nSources[0].media[index]
        })
      })

      return {
        link: links
      }
    }
  },
  computed: {
    // eslint-disable-next-line no-undef
    version: () => process.env.NODE_ENV === 'production' ? `?v=${__VERSION__}` : '',
    classList: vm => ({ 'lazy-loading': vm.loading === 'lazy', 'lazy-loaded': vm.imgLoaded && vm.loading === 'lazy' }),
    isTransparent () {
      return ['png', 'webp', 'gif'].includes(this.originalFormat)
    },
    originalFormat () {
      return getFileExtension(this.src)
    },
    nFormat () {
      if (this.format) {
        return this.format
      }
      if (this.originalFormat === 'svg') {
        return 'svg'
      }
      return 'webp'
    },
    nLegacyFormat () {
      if (this.legacyFormat) {
        return this.legacyFormat
      }
      const formats = {
        webp: this.isTransparent ? 'png' : 'jpeg',
        svg: 'png'
      }
      return formats[this.nFormat] || this.originalFormat
    },
    nSources () {
      if (this.nFormat === 'svg') {
        return [{
          srcset: this.src
        }]
      }
      const formats = this.nLegacyFormat !== this.nFormat ? [this.nFormat, this.nLegacyFormat] : [this.nFormat]
      const sources = formats.map((format) => {
        const sizesValues = []
        const sizes = (this.sizes || this.$img.options.screens).split(' ').map((size) => {
          const sizeInfo = size.split(':')
          const value = parseFloat(sizeInfo[1])
          const unit = sizeInfo[1].replace(value, '')
          sizesValues.push(unit === 'vw' ? `${value}%` : value)
          return `${sizeInfo[0]}:${value}${unit}`
        }).join(' ')
        const sizes2x = (this.sizes || this.$img.options.screens).split(' ').map((size) => {
          const sizeInfo = size.split(':')
          const value = parseFloat(sizeInfo[1])
          const unit = sizeInfo[1].replace(value, '')
          return `${sizeInfo[0]}:${value * 2}${unit}`
        }).join(' ')
        const image = this.$img.getSizes(this.src, {
          ...this.nOptions,
          sizes,
          modifiers: {
            ...this.nModifiers,
            format
          }
        })
        const image2x = this.$img.getSizes(this.src, {
          ...this.nOptions,
          sizes: sizes2x,
          modifiers: {
            ...this.nModifiers,
            width: this.nModifiers.width * 2,
            height: this.nModifiers.height ? this.nModifiers.height * 2 : undefined,
            format
          }
        })
        // const media = image.sizes.split(', ').map((size) => `${size.split(') ')[0]})`)
        // media[media.length - 1] = `(min-width: ${parseFloat(media[media.length - 2].replace('(max-width: ', '').replace('px)', '')) + 1}px)`
        // sizes[sizes.length - 1] = sizes[sizes.length - 2].replace('max', 'min')
        const media = image.sizes.split(', ').map((mediaItem) => {
          return `${mediaItem.split(') ')[0]})`
        }).map((mediaItem, index, mediaArray) => {
          if (index !== 0 && index !== mediaArray.length - 1) {
            mediaItem = `(min-width: ${parseFloat(mediaArray[index - 1].replace('(max-width: ', '').replace('px)', '')) + 1}px) and ${mediaItem}`
          } else if (index === mediaArray.length - 1) {
            mediaItem = `(min-width: ${parseFloat(mediaArray[mediaArray.length - 2].replace('(max-width: ', '').replace('px)', '')) + 1}px)`
          }
          return mediaItem
        })
        const src = image.src
        const srcset = image.srcset
        const srcset2x = image2x.srcset
        return {
          src,
          type: `image/${format}`,
          media,
          srcset: srcset.split(', ').map(src => src.split(' ')[0]),
          srcset2x: srcset2x.split(', ').map(src => src.split(' ')[0]),
          widths: sizesValues
        }
      })
      return sources
    }
  },
  created () {
    if (process.server && process.static) {
      // eslint-disable-next-line no-unused-expressions
      this.nSources
    }
  }
})
</script>
