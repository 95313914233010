<template>
  <section class="section section--product">
    <div class="section-inner">
      <b-container>
        <b-row align-h="center">
          <b-col cols="auto" order-md="2" class="text-center mb-5 mb-md-0 ml-md-5">
            <!--            <div class="snow-man">-->
            <!--              <snow-man />-->
            <!--              <span>Veselé Vánoce <br>s čistým karavanem</span>-->
            <!--            </div>-->
            <div class="star-badge">
              <star-badge />
              <span>Novinka <br>v ČR!</span>
            </div>
            <jb-picture
              :preload="true"
              :src="`/images/product.png`"
              sizes="md:130px lg:230px"
              :img-attrs="{class: 'img-fluid', alt: '1000 ml láhev dpc. 990,00 Kč', width: 230, height: 697}"
            />
            <figcaption>
              1000 ml láhev dpc. 990,00 Kč
            </figcaption>
          </b-col>
          <b-col md="12" lg class="product-desc">
            <ul>
              <li>Odstraňovač tmavých skvrn po dešti</li>
              <li>Ochrana před hmyzem</li>
              <li>Čistič plastů</li>
              <li>S obsahem vosku</li>
            </ul>
            <h2 class="text-center">
              Pro obytné vozy, karavany, <br>plachty a lodě! <br>Vyčištěno, ošetřeno a
              zakonzervováno!
            </h2>

            <b-row align-h="center">
              <b-col cols="10" sm="8" md="10" lg="9">
                <b-button
                  variant="primary"
                  to="/objednat/"
                  class="btn-star"
                  block
                >
                  Objednat
                </b-button>
                <b-button
                  variant="primary"
                  to="/pred-a-po-pouziti/"
                  class="btn-star"
                  block
                >
                  Před a po použití
                </b-button>
                <b-button
                  variant="primary"
                  href="/aplikace/"
                  class="btn-star"
                  block
                >
                  Aplikace
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </section>
</template>

<script>
import StarBadge from '~/assets/svg/star-badge.svg?inline'
// import SnowMan from '~/assets/svg/snowman.svg?inline'
import JbPicture from '~/components/Core/JbPicture/JbPicture'

export default {
  name: 'ProduktSection',
  components: {
    StarBadge,
    // SnowMan,
    JbPicture
  }
}
</script>

<style lang="scss">
@import "~assets/scss/vars/initial";
@import "~assets/scss/sections/produkt-section";
</style>
